.App {
  overflow: hidden;
  text-align: start;  
  font-weight: 500; 
  background-image: url('../src/assets/stadium-bg2.avif');
  background-repeat: no-repeat;
  background-size: cover; 
  min-height: 100vh;
}
.hero-container{
  margin: auto;
  padding: 20px 20px;
  background-color: rgb(212 211 211 / 72%);
  border-radius: 5px;
  /* min-height: 1100px; */
}

.conatiner-xl {
  max-width: 1660px;
}
.conatiner-lg {
  max-width: 1350px !important;
}

body::-webkit-scrollbar {
  display: none !important; /*hide global scroll bar */
}
.jcc {
  justify-content: center;
}
.jcs {
  justify-content: start;
}
.jce {
  justify-content: end;
}
.jcsb{
  justify-content: space-between;
}
.aic {
  align-items: center;
}
.aie {
  align-items: end;
}
.tac {
  text-align: center;
}
.tu {
  text-transform: capitalize;
}
.w-100 {
  width: 100% !important;
}
.border-gray {
  border: 1px solid lightgray;
}
.fdc {
  flex-direction: column;
}
.br5 {
  border-radius: 5px;
}
.fw-800 {
  font-weight: 800;
}
.bg-black {
  background-color: black;
}
.text-white{
  color: white;
}
.social-icon-header{
  font-size: 30px;
  cursor: pointer;
}
.loader-cont{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

/* To hide close icon in date picker */
.anticon anticon-close-circle{
  display: none;
}
.bold-text{
  font-weight: 900 !important;
  cursor: pointer;
}
/* Hide 'powered by Google text from Google Autocomplete' */
.pac-container::after {
  display: none !important;
}
.pac-container{
  border-radius: 5px !important;
}
/* for making table no. of rows text align vertically centerd in admin dashboard ( in pagination) */
.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel  {
  margin-bottom: 0rem !important;
}
.locator-inp{
  position: absolute;
    top: 18px;
    right: 2px;
    width: 50px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #d3d3d375;
    cursor: pointer;
}
.PhoneInputInput {
  font-size: 20px;
  padding: 8px;
  border: 1px solid lightgray;
}
.PhoneInputInput:focus{
  outline-color: #1976d2 !important;
}
@media (max-width: 1200px) {
  .App {
   padding: 20px 18px;
  }

}
@media (max-width: 760px) {
  .hero-container {
   padding: 0;
  }

}